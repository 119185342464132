import React from 'react';

import { Layout } from '../components/Layout';

const Blog = () => (
  <Layout>
    <h1>Blog</h1>
  </Layout>
);

export default Blog;
